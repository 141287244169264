var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('x-data-table',_vm._b({attrs:{"headers":_vm.showedHeaders,"items":_vm.showedPoints,"item-class":"text-body-1","caption":_vm.points.objectName || 'Без привязки',"use-caption-as-index":false,"hide-default-footer":true},on:{"click:row":({ number }) => {
      _vm.emitUpdateSliderPreloaderEvt(true);
      _vm.$emit('update:slider', true);

      _vm.chooseAccountingPoint(
        _vm.points.pointList.find((point) => point['номер'] === number)
      ).finally(() => {
        _vm.emitUpdateSliderPreloaderEvt(false);
      });
    }},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('table-dropdown',{attrs:{"items":_vm.dropdownItems,"column":_vm.columnStates},on:{"update:column":function($event){_vm.columnStates=$event},"on-activator":function($event){return _vm.$emit('update:wall', true)}}})]},proxy:true}])},'x-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }